import DinnerCard from "../components/DinnerCard";
import {Container, Grid} from "@material-ui/core";
import {Doc} from "typesaurus";
import {Dinner} from "../../types";
import {makeStyles} from "@material-ui/core/styles";

interface FeedInformation {
    dinners?: Doc<Dinner>[]
}

const useStyles = makeStyles({
    grid: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50,
    }
})

// Profile-section displaying a collection of dinners
export default function ProfileFeed(props:FeedInformation) {
    const classes = useStyles()
    return (
        <Container>
            <Grid container spacing={3} className={classes.grid} >
                {
                    (props.dinners != null?
                            props.dinners.map(dinner => dinner &&
                                <DinnerCard
                                    id={dinner.ref.id}
                                    title={dinner.data.title}
                                    expenses={dinner.data.expenses}
                                    description={dinner.data.description}
                                    location={dinner.data.location}
                                    takenSeats={dinner.data.participants.length}
                                    seats={dinner.data.seats}
                                    time={dinner.data.time}
                                    imageUrl={dinner.data.imgUrl}
                                />
                                )
                            :
                            <p>...</p>
                    )
                }
            </Grid>
        </Container>
    )
}