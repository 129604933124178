import {match, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {useGet, useGetMany, useOnGet, useOnQuery} from "@typesaurus/react";
import {
    add, Collection,
    collection,
    Doc, get, limit, order, Ref,
    ref,
    subcollection,
    value
} from "typesaurus";
import {Conversation, Dinner, Message, User} from "../../types";
import CircularProgressDelayed from "../components/CircularProgressDelayed";
import {
    Avatar,
    Box,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import Navbar from "../components/Navbar";
import {Send} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import ButtonWithLoading from "../components/ButtonWithLoading";
import {auth} from "../firebase";
import {Link} from "react-router-dom"
import useOnScreen from "../utils/useOnScreen";
import {format} from "date-fns";
import {blue, grey} from "@material-ui/core/colors";
import useOnQueryWithoutReset from "../utils/useOnQueryWithoutReset";
import {useAuthState} from "react-firebase-hooks/auth";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: 'calc(100vh - 64px)',
        backgroundColor: "white",
    },
    chatMessage: {
        marginBottom: 10,
        width: '96%',
        marginLeft: '2%',
    },
    chatMessageContent: {
        borderRadius: 15,
        padding: '10px 10px 10px 10px',
    },
    chatMessageMine: {
        backgroundColor: blue.A400,
        color: 'white',
    },
    chatMessageYours: {
        backgroundColor: grey['200'],
    },
    chatMessageName: {
        width: '96%',
        marginLeft: '2%',
    },
    messageField: {
        width: '95%',
    },
    scrollColumn: {
        maxHeight: '100%',
        minHeight: '100%',
        overflowY: 'scroll',
        overscrollBehaviour: 'contain',
    },
    messageList: {
        height: 'calc(100% - 60px)'
    },
    listItem: {
        borderRadius: 10,
        width: '95%',
        marginLeft: '2.5%',
    },
    controls: {
        paddingTop: 10,
    },
})

const conversations = collection<Conversation>('conversations')
const users = collection<User>('users')
const dinners = collection<Dinner>('dinners')

/**
 * The page containing the chat system, either as panels or as separate pages on mobile.
 */
export default function ChatPage() {
    const classes = useStyles()
    const { path } = useRouteMatch();

    const [user, userState] = useOnGet(users, auth.currentUser!.uid)
    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('sm'))

    if (user) {
        if (xs) {
            return (
                <>
                    <Switch>
                        <Route path={`${path}`} exact>
                            <Navbar backArrow/>
                            <ConversationsPanel dinners={user.data.joinedDinners} ownedDinners={user.data.dinners} conversations={user.data.conversations}/>
                        </Route>
                        <Route path={`${path}/:id`}>
                            <Navbar backArrow path={`${path}`}/>
                            <ChatPanelWrapper/>
                        </Route>
                    </Switch>
                </>
                )
        } else {
            return (
                <>
                    <Navbar backArrow/>
                    <Grid container className={classes.root}>
                        <Grid item md={3} sm={4} xs={12} className={classes.scrollColumn}>
                            <ConversationsPanel dinners={user.data.joinedDinners} ownedDinners={user.data.dinners} conversations={user.data.conversations}/>
                        </Grid>
                        <Grid item md={6} sm={8} xs={12} className={classes.messageList}>
                            <Route path={`${path}/:id`}>
                                <ChatPanelWrapper/>
                            </Route>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
    if (userState.error) {
        console.error(userState.error)
    }
    return (
        <>
            <Navbar backArrow/>
            {userState.loading && <CircularProgressDelayed/>}
            {userState.error && <div>Error</div>}
        </>
    )
}

interface ConversationListProps {
    conversations: {[key: string]: string}
    dinners: Ref<Dinner>[]
    ownedDinners: Ref<Dinner>[]
}

/**
 * The panel (or page on mobile) containing a list of all the user's conversations, including dinner conversations.
 */
function ConversationsPanel(props: ConversationListProps) {
    const userIds = Object.keys(props.conversations)
    const [userList, userListState] = useGetMany(users, userIds)
    const dinnerIds = props.dinners.map(m => m.id)
    const ownedDinnerIds = props.ownedDinners.map(m => m.id)
    const [dinnerList, dinnerListState] = useGetMany(dinners, dinnerIds)
    const [ownedDinnerList, ownedDinnerListState] = useGetMany(dinners, ownedDinnerIds)
    const [authUser] = useAuthState(auth)
    const [currentUser, currentUserStatus] = useGet(users, authUser?.uid)
    const routeMatch: match<{id: string}> | null = useRouteMatch('/chat/:id?')

    console.log(userList)

    if (userList && dinnerList && ownedDinnerList && currentUser) {
        return (
            <List>
                {
                    ownedDinnerList.map(d => <ConversationLink key={d.ref.id} active={d.ref.id === routeMatch?.params.id} dinner={d} conversation={d.ref.id} thisUser={currentUser}/>)
                }
                {
                    dinnerList.map(d => <ConversationLink key={d.ref.id} active={d.ref.id === routeMatch?.params.id} dinner={d} conversation={d.ref.id} thisUser={currentUser}/>)
                }
                {
                    userList.map(u =>
                        <>
                            <ConversationLink key={props.conversations[u.ref.id]} active={props.conversations[u.ref.id] === routeMatch?.params.id} otherUser={u} conversation={props.conversations[u.ref.id]} thisUser={currentUser}/>
                        </>
                        )
                }
            </List>
        )
    }
    return (
        <>
            {(userListState.loading || currentUserStatus.loading || dinnerListState.loading || ownedDinnerListState.loading) && <CircularProgressDelayed/>}
            {(userListState.error || currentUserStatus.error || dinnerListState.error || ownedDinnerListState.error) && <div>Error</div>}
        </>
    )
}

interface ConversationLinkProps {
    otherUser?: Doc<User>
    dinner?: Doc<Dinner>
    thisUser: Doc<User>
    conversation: string
    active: boolean
}

/**
 * The individual conversations in the list. They act as links to the conversation, and show name/avatar and last message preview.
 */
function ConversationLink(props: ConversationLinkProps) {
    const classes = useStyles()

    const [sender, setSender] = useState<string>("")

    const conversation = props.otherUser ?
        subcollection<Message, Conversation>('messages', conversations)(props.conversation) :
        subcollection<Message, Dinner>('messages', dinners)(props.conversation)
    const [lastMessage] = useOnQuery(
        conversation,
        [
            order('time', 'desc'),
            limit(1)
        ],
        {serverTimestamps: "estimate"}
    )
    const [owner] = useGet(users, props.dinner?.data.owner.id)

    const truncate = (str: string | undefined, n: number) => str && (str.length > n) ? str.substr(0, n-1) + '…' : str


    useEffect(() => {
        const getUser = async () => {
            if (lastMessage && lastMessage[0]) {
                if (lastMessage[0].data.from.id === props.thisUser.ref.id) {
                    return props.thisUser.data.name.firstName + " " + props.thisUser.data.name.lastName
                } else {
                    if (props.otherUser) {
                        return props.otherUser.data.name.firstName + " " + props.otherUser.data.name.lastName
                    } else {
                        const user = await get(users, lastMessage[0].data.from.id)
                        return user?.data.name.firstName + " " + user?.data.name.lastName
                    }
                }
            }
        }
        getUser().then(u => setSender(u || ""))
    }, [lastMessage, props])

    console.log(conversation, lastMessage)

    const message = truncate(lastMessage && lastMessage[0] && lastMessage[0].data.content, 20)

    const date = (() => {
        if (lastMessage && lastMessage[0]) {
            const now = new Date()
            const messageDate = lastMessage[0].data.time as Date
            if (messageDate.getDay() === now.getDay()) {
                return format(messageDate, "H':'mm")
            }
            if (messageDate.getFullYear() === now.getFullYear()) {
                return format(messageDate, "d MMM")
            }
            return format(messageDate, "dd'/'MM'/'y")
        }
    })()

    return (
        <ListItem
            selected={props.active}
            className={classes.listItem}
            alignItems="flex-start"
            component={Link}
            to={"/chat/" + props.conversation}
        >
            {props.otherUser?.data.imgUrl || props.dinner?.data.imgUrl ?
                <ListItemAvatar>
                    <Avatar alt={props.otherUser?.data.name.firstName || props.dinner?.data.title}
                            src={props.otherUser?.data.imgUrl || props.dinner?.data.imgUrl}/>
                </ListItemAvatar>
                :
                <Skeleton><ListItemAvatar><Avatar/></ListItemAvatar></Skeleton>
            }
            <ListItemText
                primary={props.otherUser
                    ? (props.otherUser.data.name.firstName + " " + props.otherUser.data.name.lastName)
                    : <>{props.dinner?.data.title} – {(owner ? (owner.data.name.firstName + " " + owner.data.name.lastName) : <Skeleton style={{display: "inline"}}><span>The size of a name (ish)</span></Skeleton>)}</>
                }
                secondary={
                    sender && message && date ?
                        <>
                            <Typography
                                component="span"
                                variant="body2"
                            >
                                {sender}
                            </Typography>
                            : {message} – {date}
                        </>
                        : <Skeleton/>
                }
            />
        </ListItem>
    )
}

/**
 * Wrapper around the chat panel.
 * Used to figure out if the conversation is a dinner or a DM conversation, and give the right root document.
 */
// TODO: This might be superfluous with solid use of refs higher up?
function ChatPanelWrapper () {
    const {id} = useParams<{id: string}>();

    const [messagesCollection, setMessagesCollection] = useState<Collection<Message> | undefined>()
    const [conversationRoot, setConversationRoot] = useState<Doc<Dinner | Conversation> | undefined | null>()

    useEffect(() => {
        const getCollection = async (): Promise<[Collection<Message>, Doc<Dinner | Conversation> | null]> => {
            let conversation
            try {
                conversation = await get(conversations, id)
            } catch (FirebaseError) {
                conversation = false
            }
            if (conversation) {
                const root = await get(conversations, id)
                return [subcollection<Message, Conversation>('messages', conversations)(id), root]
            }
            const root = await get(dinners, id)
            return [subcollection<Message, Dinner>('messages', dinners)(id), root]
        }
        getCollection().then(([col, root]) => {
            setMessagesCollection(col)
            setConversationRoot(root)
        })
    }, [id])

    if (messagesCollection && conversationRoot) {
        return <ChatPanel messagesCollection={messagesCollection} conversationRoot={conversationRoot}/>
    }
    return <CircularProgressDelayed/>
}

/**
 * The panel containing the actual chat messages.
 * @param messagesCollection - The collection of messages, either a subcollection of dinners or of conversations.
 * @param conversationRoot - The document, either a Dinner or Conversation, at the root of the conversation.
 */
function ChatPanel ({messagesCollection, conversationRoot}: {messagesCollection: Collection<Message>, conversationRoot: Doc<Dinner | Conversation>}) {
    const classes = useStyles()
    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('sm'))
    const [user] = useAuthState(auth)

    const chatUserIds = "users" in conversationRoot.data ? conversationRoot.data.users.map(u => u.id) : conversationRoot.data.participants.map(u => u.id)
    const [chatUsers, chatUsersState] = useGetMany(users, chatUserIds)

    const scrollSteps = 20 //This is the size of each "chunk" of new messages as you scroll.

    const handleSendMessage = async (message: string): Promise<any> => {
        const users = collection<User>('users')
        if (user) {
            return await add(messagesCollection, {
                from: ref(users, user.uid),
                time: value('serverDate'),
                received: false,
                read: false,
                content: message,
            })
        }
    }

    const [scrollLimit, setScrollLimit] = useState(scrollSteps)
    // These two are weird, kind of
    // They are used to control message scrolling, and break a few "rules"
    // They are basically meant to keep track of the "lifecycle" of the message loading,
    // which type of message load was triggered, and thus where to scroll
    // (down or to where the user was before loading more messages)
    const [loadedMore, setLoadedMore] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)

    const [messages, messagesState] = useOnQueryWithoutReset(
        messagesCollection,
        [order("time", "desc"), limit(scrollLimit)],
        {serverTimestamps: "estimate"}
    )

    // These are used for scrolling and updating.
    const bottomRef = useRef<HTMLDivElement>(null) // Bottom of the messages, for scrolling to
    const topRef = useRef<HTMLDivElement>(null) // Top of the messages, to detect when to load more
    const midRef = useRef<HTMLDivElement>(null) // Where the top was before the last round of loading more

    const containerRef = useRef(null) // Measure scroll relative to, since we're not scrolling the whole page
    const atTop = useOnScreen(topRef, {root: xs ? null : containerRef}) // Hook I wrote (well, partly stole) for this

    // This one is weird
    // We need to scroll at various points
    const scrollToPosition = () => {
        if (firstLoad && messages && messages.length > 1) {
            // We need to wait for more than one message to load
            // (firestore has one in the buffer because of the preview in ConversationLink).
            bottomRef.current?.scrollIntoView()
            setFirstLoad(false) // This is never touched again
        } else if (loadedMore && messages && messages.length > 1) {
            // Same, but when loading more messages at the top.
            // Keeps the scroll position where you were
            midRef.current?.scrollIntoView()
            setLoadedMore(false)
        } else if (messages && messages.length > 1) {
            // loadedMore is false, so the new message must be at the bottom.
            bottomRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }

    useLayoutEffect(() => {
        // useLayoutEffect because I want it synchronously.
        // useEffect (which is async) would cause flickering and jumping.
        scrollToPosition()
        // We need this to only actually refresh when the message list updates,
        // not every time loadedMore changes, for instance.
        // Then you would load more, scroll right, set loadedMore to false, and then scroll to bottom.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    const loadMore = () => {
        setScrollLimit(current => current + scrollSteps)
        setLoadedMore(true)
    }

    useEffect(() => {
        if (atTop) {
            loadMore()
        }
    }, [atTop])

    useLayoutEffect(() => {
        setFirstLoad(true)
    }, [messagesCollection])

    console.log(atTop)


    if (messages && chatUsers) {
        const messagesList = messages.map(m => <ChatMessage key={m.ref.id} sender={chatUsers.find(u => u.ref.id === m.data.from.id)?.data} message={{...m.data}}/>)
        //Insert ref at what used to be the top.
        messagesList.splice(scrollLimit - scrollSteps, 0, <div ref={midRef} style={{height: 0}}/>)

        return (
            <>
                <Box {...{ ref: containerRef } as any} className={classes.scrollColumn} style={xs ? {height: '80vh'} : {}}>
                    <div ref={topRef}/>
                    {messagesState.loading && <CircularProgressDelayed alignVertical={false} delay="100ms"/>}
                    {messagesList.slice(0).reverse()}
                    <div ref={bottomRef}/>
                </Box>
                <ChatControls onSendMessage={handleSendMessage}/>
            </>

        )
    }
    else if (messagesState.loading || chatUsersState.loading) {
        return (
            <Box className={classes.scrollColumn}>
                <CircularProgressDelayed/>
            </Box>
        )
    }
    if (messagesState.error) {
        console.error(messagesState.error)
    }
    if (chatUsersState.error) {
        console.error(chatUsersState.error)
    }
    return <div>Error</div>
}

interface ChatMessageProps {
    message: Message
    sender: User | undefined
}

/**
 * A single message in a chat, with name, avatar, time, and content.
 * @param message - The message
 * @param sender - The User who sent the message.
 * @constructor
 */
function ChatMessage({message, sender}: ChatMessageProps) {
    const classes = useStyles()
    const [user] = useAuthState(auth)
    const justify = message.from.id === user?.uid ? "flex-end" : "flex-start"
    const styling = message.from.id === user?.uid ? classes.chatMessageMine : classes.chatMessageYours
    const date = (() => {
        const now = new Date()
        const messageDate = message.time as Date
        if (messageDate.getDay() === now.getDay()) {
            return format(messageDate, "H':'mm")
        }
        return format(messageDate, "d MMM y, H':'mm")
    })()
    return (
        <>
            <Grid className={classes.chatMessageName} container justify={justify}>
                <Grid item>
                    <Typography variant="body2">
                        {sender?.name.firstName} {sender?.name.lastName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.chatMessage} container justify={justify}>
                {message.from.id !== user?.uid && <Grid item>
                    <Avatar src={sender?.imgUrl || ""} style={{marginRight: 5}}/>
                </Grid>
                }
                <Grid item className={classes.chatMessageContent + ' ' + styling}>
                    <Grid container justify={justify}>
                        <Grid item>
                            <Typography variant="body1">{message.content}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify={justify}>
                        <Grid item>
                            <Typography variant="body2">{date}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {message.from.id === user?.uid && <Grid item>
                    <Avatar src={sender?.imgUrl || ""} style={{marginLeft: 5}}/>
                </Grid>
                }
            </Grid>
        </>
    )
}

interface ChatControlsProps {
    onSendMessage: (message: string) => Promise<any>
}

/**
 * The text box and send button.
 * @param props - Function to send the message.
 */
const ChatControls = (props: ChatControlsProps) => {
    const classes = useStyles()

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSendMessage = async () => {
        if (message.trim()) {
            setLoading(true)
            try {
                await props.onSendMessage(message.trim())
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
            setMessage("")
        }
    }

    return (
        <Grid container className={classes.controls}>
            <Grid item xs={1}/>
            <Grid item sm={9} xs={7}>
                <TextField
                    multiline
                    className={classes.messageField}
                    size="small"
                    variant="outlined"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyPress={e => e.key === "Enter" ? handleSendMessage() : null}
                />
            </Grid>
            <Grid item xs={1}>
                <ButtonWithLoading
                    variant="contained"
                    color="primary"
                    endIcon={<Send/>}
                    onClick={handleSendMessage}
                    isLoading={loading}
                >
                    Send
                </ButtonWithLoading>
            </Grid>
        </Grid>
    )
}
