import {Card, CardMedia, Grid, Link} from "@material-ui/core";

interface advertisementCardInt {
    imgUrl:string;
    imgWidth:number;
    imgHeight:number;
    sponsorUrl:string;
}

export default function AdvertisementCard(props:advertisementCardInt) {
    let imgWidth = 300
    let imgHeight: number

    // Adjust imgHeight
    let imgScaleFactor = imgWidth / props.imgWidth
    imgHeight = props.imgHeight * imgScaleFactor

    if (imgHeight > 450) {
        // Reset imgHeight and adjust imgWidth instead
        imgHeight = 450
        imgScaleFactor = imgHeight / props.imgHeight
        imgWidth = props.imgWidth * imgScaleFactor
    }

    let styles = {
        Grid: {
            marginTop: 12,
            padding: 0,
            width: imgWidth,
        },
        Card: {
            height: imgHeight,
            padding: 0,
            margin: 7,
        },
        Media: {
            height: imgHeight,
            width: imgWidth,
        }
    }

    return (
        <>
            <Grid item style={styles.Grid}>
                <Link href={props.sponsorUrl} target={"_blank"}>
                    <Card style={styles.Card} >
                        <CardMedia
                            style={styles.Media}
                            image={props.imgUrl}
                        />
                    </Card>
                </Link>
            </Grid>
        </>
    )
}