import TodayIcon from '@material-ui/icons/Today'
import ScheduleIcon from '@material-ui/icons/Schedule'
import PersonIcon from '@material-ui/icons/Person'
import CreditCardIcon from '@material-ui/icons/CreditCard';

interface DinnerSubheaderInfo {
    time: Date;
    seats: number;
    takenSeats: number;
    expenses: number;
}

function generateDateString(date:Date) {
    // Add trailing 0s and slice the last two characters
    return ('0' + date.getDate()).slice(-2) + '.'
        + ('0' + (date.getMonth()+1)).slice(-2) + '.'
        + date.getFullYear();
}
function generateTimeString(date:Date) {
    // Add trailing 0s and slice the last two characters
    return ('0' + date.getHours()).slice(-2) + ':'
        + ('0' + (date.getMinutes())).slice(-2)
}
// Component displaying detailed information about a particular dinner
function DinnerSubheader(props:DinnerSubheaderInfo) {
    let styles = {
        Div: {
            display: "flex",
            alignItems: "center"
        },
        Icon: {
            marginLeft: "10px"
        }
    }
    const date:string = generateDateString(props.time)
    const clock:string = generateTimeString(props.time)
    return (
        <div >
            <div style={styles.Div}>
                <TodayIcon />{date}<ScheduleIcon style={styles.Icon} />{clock}
                <PersonIcon style={styles.Icon} />{props.takenSeats}/{props.seats}
                <CreditCardIcon style={styles.Icon} /> {props.expenses ? props.expenses : 0} kr
            </div>
        </div>

    )
}

export default DinnerSubheader