import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            color: "white",
        },
        icon: {
            color: "white",
        },
        listItem: {
            display: "flex",
            justifyContent: "center",
        },
        link: {
            color: "white",
        }
    }),
);

function SimpleList() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem>
                    <ListItemIcon className={classes.listItem}>
                        <PhoneIcon className={classes.icon} />
                    </ListItemIcon>
                    <Link className={classes.link} href={"tel: 4712345678"} >
                        <ListItemText primary="(+47) 123 45 678"/>
                    </Link>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <EmailIcon className={classes.icon} />
                    </ListItemIcon>
                    <Link className={classes.link} href={"mailto: samspisnad@gmail.com"} >
                        <ListItemText primary="samspisnad@gmail.com"/>
                    </Link>
                </ListItem>
            </List>
        </div>
    );
}
let styles={
    Footer: {
        color: "black",
        paddingTop: "1em",
        height: "10vh",
    }
}

export default function Footer() {
    return (
        <>
        <div style={styles.Footer} color={"primary"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <SimpleList/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}