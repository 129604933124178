import {useState, MouseEvent, ReactNode} from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import { Link as RouterLink } from "react-router-dom"
import Menu from '@material-ui/core/Menu'
import {auth} from '../firebase'
import {useAuthState} from "react-firebase-hooks/auth";
import useIsAdmin from "../utils/useIsAdmin";
import {ArrowBack} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        link: {
            textDecoration: "none",
            color: "black"
        },
        linkInverted: {
            textDecoration: "none",
            color: "white"
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

interface NavbarProps {
    backArrow?: boolean
    path?: string
    additionalIcons?: ReactNode[]
}

/**
 * A Navbar for the top of the page. Includes the profile icon if logged in.
 *
 * @param {boolean} [backArrow] - Displays a back arrow if passed.
 * @param {string} [path] - Where the back arrow leads. Defaults to "/".
 * @param filterProps
 * @constructor
 */
function Navbar({backArrow, path="/", additionalIcons}: NavbarProps) {
    const classes = useStyles();
    const [authUser] = useAuthState(auth)
    const admin = useIsAdmin(authUser)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        handleClose()
        auth.signOut()
    }

    return (
        <AppBar position="sticky" className={classes.root}>
            <Toolbar>
                {backArrow &&
                <IconButton component={RouterLink} to={path} color="inherit" edge="start" aria-label="tilbake">
                    <ArrowBack />
                </IconButton>}
                <Typography variant="h6" className={classes.title}>
                    <RouterLink className={classes.linkInverted} to={"/"} >Samspisnad</RouterLink>
                </Typography>
                {additionalIcons?.map(i => <div key={i?.toString()}>{i}</div>)}
                {authUser && (
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <RouterLink to={"/profile/" + authUser.uid} className={classes.link} ><MenuItem onClick={handleClose}>Profil</MenuItem></RouterLink>
                                <RouterLink to={"/profileEdit"} className={classes.link} ><MenuItem onClick={handleClose}>Rediger Profil</MenuItem></RouterLink>
                                <RouterLink to={"/chat"} className={classes.link} ><MenuItem onClick={handleClose}>Samtaler</MenuItem></RouterLink>
                                <RouterLink to={"/createDinner"} className={classes.link} ><MenuItem onClick={handleClose}>Opprett Middagsarrangement</MenuItem></RouterLink>
                                {admin &&
                                    <RouterLink to={"/adminPanel"} className={classes.link} ><MenuItem onClick={handleClose}>Administrator-panel</MenuItem></RouterLink>
                                }
                                <Typography className={classes.link} ><MenuItem onClick={logOut}>Logg ut</MenuItem></Typography>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>

    );
}

export default Navbar