import { useState } from "react";
import DinnerCard from "../components/DinnerCard"
import {Container, Grid, Fab, Typography, Divider, createStyles} from "@material-ui/core"
import Navbar from "../components/Navbar"
import Filter from "../components/Filter"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Link as RouterLink} from "react-router-dom";
import {collection, Doc, where} from "typesaurus";
import {Advertisement, Allergy, Dinner, User} from "../../types";
import {useAll, useOnGet, useQuery} from "@typesaurus/react";
import CircularProgressDelayed from "../components/CircularProgressDelayed";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined';
import {makeStyles, Theme} from "@material-ui/core/styles";
import firebase from "../firebase";
import Footer from "../components/Footer";
import AdvertisementCard from "../components/AdvertisementCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        Grid: {
            display: "flex",
            justifyContent: "center",
            margin: "0 auto 20px auto",
            width: "100%"
        },
        Fab: {
            position: "fixed",
            left: "20px",
            bottom: "20px",
            zIndex: 10
        },
        Icon: {
            marginRight: "5px"
        },
        Link: {
            textDecoration: "none"
        },
        Container: {
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        SectionIcon: {
            marginRight: "10px",
            marginBottom: "-4px"
        },
    }),
)


function Home() {
    const classes = useStyles()
    const [dateNow] = useState(new Date())
    const [cost, setCost] = useState<number[]>([0, 100]);
    const [search, setSearch] = useState("");
    const [filteredAllergiesBool, setFilteredAllergies] =  useState<boolean[]>([]);
    const [date, setDate] = useState<Date | null>(new Date());
    const dinners = collection<Dinner>('dinners')
    const users = collection<User>('users')
    const ads = collection<Advertisement>('ads')
    const allergies = collection<Allergy>('allergies')

    let [allAllergies] = useAll(allergies)
    let filteredAllergies = filteredAllergiesBool.map(a => a.toString())
    // unfilteredAllergies = allAllergies - filteredAllergies, or allAllergies if filteredAllergies.length == 0
    let unfilteredAllergies:string[] = allAllergies ?
        (
            filteredAllergiesBool.length > 0 ?
                allAllergies.map(a => a.ref.id).filter(a => !filteredAllergies.includes(a)) : allAllergies.map(a => a.ref.id)
        ) : []
    // Get dinners based on time filter
    let [dinnerList, dinnerListStatus] = useQuery(dinners, [
        where("time", ">", date || dateNow),
    ])
    let [adList,] = useAll(ads)
    let loggedInUser = firebase.auth().currentUser
    let [user] = useOnGet(users, loggedInUser ? loggedInUser.uid : undefined)
    let joinedDinners : Doc<Dinner>[] = [] // List of (upcoming) dinners that the user has joined
    if (dinnerList && user && user.data.joinedDinners) {
        // Get a string-array of the IDs of all dinners that the user has joined
        let joinedDinnerIDs = user.data.joinedDinners.map(dinner => dinner.id)
        // From the list of all *upcoming* dinners (inside dinnerList variable), filter only the dinners that the user has joined
        joinedDinners = dinnerList.filter(dinner => joinedDinnerIDs.some(joinedDinnerID => joinedDinnerID === dinner.ref.id))
    }

    return (
        <>
            <div className={classes.root} >
                <Navbar additionalIcons={[<Filter onSearchChange={setSearch} onCostChange={setCost} onAllergyChange={setFilteredAllergies} onDateChange={setDate}/>]}/>
                <RouterLink to={"/createDinner"} className={classes.Link}>
                    <Fab variant="extended" color="primary" aria-label="add" className={classes.Fab}>
                    <AddCircleOutlineIcon className={classes.Icon} />Arranger middag
                    </Fab>
                </RouterLink>
                <Container className={classes.Container}>
                    {dinnerList && joinedDinners ?
                        <>
                            {joinedDinners.length > 0 &&
                                <>
                                    <Typography variant={"h5"}>
                                        <EventAvailableIcon className={classes.SectionIcon}/>
                                        Kommende middager der du er påmeldt
                                    </Typography>
                                    <Divider />
                                    <Grid container className={classes.Grid} spacing={3}>
                                        {
                                            joinedDinners.map(dinner =>
                                                <DinnerCard
                                                    id={dinner.ref.id}
                                                    key={dinner.ref.id}
                                                    title={dinner.data.title}
                                                    description={dinner.data.description}
                                                    location={dinner.data.location}
                                                    takenSeats={dinner.data.participants.length}
                                                    seats={dinner.data.seats}
                                                    time={dinner.data.time}
                                                    imageUrl={dinner.data.imgUrl}
                                                    expenses={dinner.data.expenses}
                                                />
                                            )
                                        }
                                    </Grid>
                                </>
                            }

                            <Typography variant={"h5"}>
                                <RestaurantOutlinedIcon className={classes.SectionIcon}/>
                                Alle kommende middager
                            </Typography>
                            <Divider />
                            <Grid container className={classes.Grid} spacing={3}>
                                {
                                    // Display all ads with front placement
                                    adList?.filter(ad => ad.data.frontPlacement).map(ad =>
                                        <AdvertisementCard
                                            key={ad.ref.id}
                                            imgUrl={ad.data.imgUrl}
                                            imgWidth={ad.data.imgSize[0]}
                                            imgHeight={ad.data.imgSize[1]}
                                            sponsorUrl={ad.data.sponsorUrl}
                                        />
                                    )
                                }
                                {
                                    dinnerList.filter(dinner =>
                                        // The dinner's cost is higher than the min cost
                                        cost[0] <= dinner.data.expenses &&
                                        // The dinner's cost is lower than the max cost
                                        dinner.data.expenses <= cost[1]
                                        // The dinner title contains the search keyword
                                        && dinner.data.title.toLowerCase().includes(search.toLowerCase())
                                        // The dinner contains no filtered allergies
                                        && dinner.data.allergies.map(a => a.id).every(allergy => unfilteredAllergies.includes(allergy))
                                    ).map(dinner =>
                                        <DinnerCard
                                            id={dinner.ref.id}
                                            key={dinner.ref.id}
                                            title={dinner.data.title}
                                            expenses={dinner.data.expenses}
                                            description={dinner.data.description}
                                            location={dinner.data.location}
                                            takenSeats={dinner.data.participants.length}
                                            seats={dinner.data.seats}
                                            time={dinner.data.time}
                                            imageUrl={dinner.data.imgUrl}
                                        />
                                    )
                                }
                                {
                                    // Display all ads without front placement
                                    adList?.filter(ad => !ad.data.frontPlacement).map(ad =>
                                        <AdvertisementCard
                                            key={ad.ref.id}
                                            imgUrl={ad.data.imgUrl}
                                            imgWidth={ad.data.imgSize[0]}
                                            imgHeight={ad.data.imgSize[1]}
                                            sponsorUrl={ad.data.sponsorUrl}
                                        />
                                    )
                                }
                            </Grid>
                        </>
                        :
                        <>
                            {dinnerListStatus.loading && <CircularProgressDelayed/>}
                            {dinnerListStatus.error && dinnerListStatus.error}
                        </>
                    }
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Home
