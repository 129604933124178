import {makeStyles} from "@material-ui/core/styles";
import {Avatar, CssBaseline, Grid, Paper} from "@material-ui/core";
import {LockOutlined} from "@material-ui/icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from "@material-ui/core/Typography";
import {ReactNode} from "react";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2028&q=80)', //sett inn bilde av mat eller noe lignende
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

interface AuthProps {
    children: ReactNode,
    text: string
}


export default function AuthPage(props: AuthProps) {
    const classes = useStyles();
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} lg={8} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} lg={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {props.text === "Log in" ? <LockOutlined /> : <ExitToAppIcon />}
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {props.text}
                    </Typography>
                    {props.children}
                </div>
            </Grid>
        </Grid>
    );

}