import {useState} from "react"
import {Grid, Typography, Divider, useMediaQuery, Box, Button, Tooltip} from "@material-ui/core";
import CallIcon from '@material-ui/icons/Call';
import CreateIcon from '@material-ui/icons/Create';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import WarningIcon from '@material-ui/icons/Warning';
import {Doc} from "typesaurus";
import {User} from "../../types";
import {Link} from "react-router-dom";
import {Chat} from "@material-ui/icons";
import ButtonWithLoading from "../components/ButtonWithLoading";
import {Redirect} from "react-router";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

interface UserInfo {
    user: Doc<User> | undefined | null;
    currentUserId: string;
    onStartConversation: () => Promise<string>
}
// Profile section displaying user information
export default function ProfileInfo(props:UserInfo) {
    const containerHeight = 130
    const isMobile = useMediaQuery( `(max-width: 960px)` );
    const isCurrentUser = props.user?.ref.id === props.currentUserId

    let styles = {
        GridContainer: {
            minHeight: containerHeight,
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        GridPicture: {
            //backgroundColor: "rgba(0,0,0,0.5)",
            minHeight: containerHeight,
            display: "flex",
            justifyContent: (isMobile ? "center" : "flex-end"),
            paddingRight: 10
        },
        GridDescription: {
            //backgroundColor: "rgba(0,0,0,0.3)",
            minHeight: containerHeight,
            padding: "5px",
            display: "block"
        },
        GridDescriptionMobile: {
            //backgroundColor: "rgba(0,0,0,0.3)",
            minHeight: containerHeight,
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        Card: {
            height: "100%"
        },
        ImageContainer: {
            height: containerHeight,
            width: containerHeight,
            borderRadius: "100%",
            backgroundSize: "cover",
            boxShadow: "0px 2px 2px rgba(0,0,0,0.4)",
        },
        Typography: {
            display: "flex",
            alignItems: "center",
            margin: "2px"
        },
        Icon: {
            marginRight: 6
        },
        Divider: {
            width: "80%",
            margin: "0 auto 20px"
        },
        Bio: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2px",
            fontStyle: "italic"
        },
        AdminIcon: {
            marginRight: "5px",
        },
    }

    // Convert array of allergy strings to displayable comma-separated text
    function allergiesToString(allergyArr:string[]) {
        return allergyArr.join(", ")
    }

    let phoneNumber = "..."
    let address = "..."
    let email = "..."
    let name = "..."
    let allergies = "..."
    let bio = "..."
    let imgUrl = ""
    let conversations: {[key: string]: string} = {}
    if (props.user) {
        // Retrieve data from user object passed in as a prop
        phoneNumber = props.user.data.contact.phone ? props.user.data.contact.phone : "N/A"
        address = props.user.data.address ? props.user.data.address : "N/A"
        email = props.user.data.contact.email ? props.user.data.contact.email : "N/A"
        name = props.user.data.name ? props.user.data.name.firstName + " " + props.user.data.name.lastName : "N/A"
        allergies = props.user.data.allergies ? allergiesToString(props.user.data.allergies.map(allergy => allergy.id)) : "Ingen allergier/preferanser"
        bio = props.user.data.bio ? props.user.data.bio : "Ingen biografi"
        imgUrl = props.user.data.imgUrl?.replaceAll(" ", "%20") || "https://i.pinimg.com/originals/54/5f/bc/545fbc0dcc5f6aeeb5c1dbc026873749.jpg"
        conversations = props.user.data.conversations || {}
    }
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState("")
    // Triggers when user clicks on the start conversation button
    const handleStartConversation = async () => {
        setLoading(true)
        const conversationId = await props.onStartConversation()
        setRedirect("/chat/"+conversationId)
    }

    if (!!redirect) { // Cast redirect string to boolean
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <Grid container justify="center" alignItems="center" style={styles.GridContainer} >
                <Grid item lg={5} md={4} sm={4} xs={12} style={styles.GridPicture} >
                    <div style={{...styles.ImageContainer, backgroundImage: `url(${imgUrl})`}}/>
                </Grid>
                <Grid item lg={7} md={6} xs={12} style={(isMobile ? styles.GridDescriptionMobile : styles.GridDescription)} >
                        {isCurrentUser ?
                            <Typography color={"primary"} variant={"h5"} style={styles.Typography} >
                                {props.user?.data.admin &&
                                    <Tooltip title="Administrator" arrow placement="top">
                                        <AssignmentIndIcon style={styles.AdminIcon} />
                                    </Tooltip>
                                }
                                {name}
                                <Link to={'/profileEdit'} >
                                    <CreateIcon />
                                </Link>
                            </Typography>
                            :
                            <Typography color={"primary"} variant={"h5"} style={styles.Typography} >
                                {name}
                            </Typography>
                        }
                    <Typography color={"inherit"} variant={"body1"} style={styles.Typography} ><CallIcon style={styles.Icon} /> {phoneNumber}</Typography>
                    <Typography color={"inherit"} variant={"body1"} style={styles.Typography} ><RoomIcon style={styles.Icon} /> {address}</Typography>
                    <Typography color={"inherit"} variant={"body1"} style={styles.Typography} ><EmailIcon style={styles.Icon} /> {email}</Typography>
                    <Typography color={"inherit"} variant={"body1"} style={styles.Typography}><WarningIcon style={styles.Icon} /> {allergies}</Typography>
                </Grid>
                <Grid item xs={8} sm={6} md={5} xl={3}>
                    <Typography align="center" color={"inherit"} variant={"body2"} style={styles.Bio}>{bio}</Typography>
                </Grid>
                {!isCurrentUser ?
                    conversations[props.currentUserId] ?
                        <Grid item xs={12}>
                            <Box textAlign="center">
                                <Link to={`/chat/${conversations[props.currentUserId]}`}>
                                    <Button
                                        variant="contained"
                                        endIcon={<Chat/>}
                                    >
                                        Åpne samtale
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box textAlign="center">
                                <ButtonWithLoading
                                    isLoading={loading}
                                    variant="contained"
                                    endIcon={<Chat/>}
                                    onClick={handleStartConversation}
                                >
                                    Start en samtale
                                </ButtonWithLoading>
                            </Box>
                        </Grid>
                    :
                    <Grid item xs={12}>
                        <Box textAlign="center">
                            <Link to="/chat">
                                <Button
                                    variant="contained"
                                    endIcon={<Chat/>}
                                >
                                    Se mine samtaler
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                }
            </Grid>
            <Divider light style={styles.Divider}/>
        </div>
    )
}