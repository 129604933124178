import {Grid, TextField} from "@material-ui/core";
import {Link, useHistory} from 'react-router-dom'
import { MouseEvent, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {auth} from "../firebase";
import ButtonWithLoading from "../components/ButtonWithLoading";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}))

export default function RegisterForm() {
    const classes = useStyles()
    const history = useHistory()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    // When user clicks on "register"
    const submit = (e: MouseEvent | KeyboardEvent) => {
        e.preventDefault()
        setLoading(true)
        auth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                history.push('/profileEdit')
            })
            .catch(error => {
                window.alert(error)
                setLoading(false)
            })
    }

    return (
        <>
            <form className={classes.form} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Epostadresse"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyPress={e => e.key === "Enter" ? submit : null}
                    type="email"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Passord"
                    type="password"
                    id="password"
                    inputProps={{role: "textbox"}}
                    autoComplete="new-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyPress={e => e.key === "Enter" ? submit : null}
                />
                <ButtonWithLoading
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={submit}
                    isLoading={loading}
                >
                    Sign Up
                </ButtonWithLoading>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link to="/">
                            Har du allerede en koto? Logg in
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
