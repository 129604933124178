import React from "react"
import {Dinner, Review, User} from "../../types";
import {collection, Doc} from "typesaurus";
import {Card, CardContent, CardMedia, CircularProgress, Typography, useMediaQuery} from "@material-ui/core";
import {useGet} from "@typesaurus/react";
import {Rating} from "@material-ui/lab";
import {Link as RouterLink} from "react-router-dom";

// Displays a review
function ReviewBox(props: {review: Doc<Review>}) {
    let styles = {
        root: {
            display: 'flex',
            justifyContent: "flex-start",
            marginBottom: "15px",
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 200,
            display: "flex",
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
        },
        playIcon: {
            height: 38,
            width: 38,
        },
        Link: {
            textDecoration: "none",
        }
    }
    const isMobile = useMediaQuery( `(max-width: 960px)` );
    let users = collection<User>('users')
    let dinners = collection<Dinner>('dinners')
    let [user, isUserLoading] = useGet(users, props.review.data.authorId) // Retrieve author
    let [dinner, isDinnerLoading] = useGet(dinners, props.review.data.dinnerId) // Retrieve dinner
    if (isDinnerLoading.loading || isUserLoading.loading) {
        return (
            <CircularProgress />
        )
    }
    return (
        <>
            <RouterLink to={"/dinner/" + props.review.data.dinnerId} style={styles.Link} >
                <Card style={styles.root}>
                    {
                        !isMobile &&
                            <CardMedia
                                style={styles.cover}
                                component={"img"}
                                src={dinner ? dinner.data.imgUrl : ""}
                            />
                    }
                    {/* @ts-ignore */}
                    <div style={styles.details}>
                        <CardContent style={styles.content}>
                            <Typography variant="body1" color="textPrimary">
                                {user && user.data.name.firstName + " " + user.data.name.lastName}
                            </Typography>
                            <Rating precision={0.5} name="read-only" value={props.review.data.rating} readOnly />
                            <Typography variant="body2" color="textPrimary">
                                <i>{props.review.data.text}</i>
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {props.review.data.time.toDateString()}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            </RouterLink>
        </>
    )
}

export default ReviewBox