import Button from "@material-ui/core/Button"
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Link,
    CardActionArea,
} from "@material-ui/core"
import {Skeleton} from "@material-ui/lab"
import DinnerSubheader from "./DinnerSubheader"
import { Link as RouterLink } from "react-router-dom"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import {ErrorBoundary} from "react-error-boundary";

interface DinnerInfo {
    id: string;
    title: string;
    description: string;
    expenses: number;
    location: string;
    imageUrl: string;
    seats: number;
    time: Date;
    takenSeats: number;
    fullWidth?: boolean;
}

function DinnerCard(props:DinnerInfo) {
    let styles = {
        Grid: {
            margin: "0",
            width: "375px",
        },
        Location: {
            display: "flex",
            alignItems: "center"
        },
        Card: {
            padding: 0
        },
        Image: {
            height: 0,
            paddingTop: '56.25%',
            filter: (props.time < new Date() ? "grayscale(100%)" : "")
        },
        ImageContent: {
            padding: 0
        },
        LinkEnabled: {
            textDecoration: "none"
        },
        LinkDisabled: { // Used when the dinner has max amount of participants
            textDecoration: "none",
            pointerEvents: "none"
        }
    }
    // Does the dinner have the max amount of participants?
    let fullReservation:boolean = props.takenSeats >= props.seats

    return (
        <ErrorBoundary FallbackComponent={DinnerCardFallback}>
            <Grid item xs={12} sm={props.fullWidth ? 12 : 10} md={props.fullWidth ? 12 : 6} lg={props.fullWidth ? 12 : 4} style={styles.Grid}>
                <Card style={styles.Card} >
                    <CardActionArea>
                        <RouterLink to={`/dinner/${props.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardHeader
                                title={props.title}
                                subheader={<DinnerSubheader time={props.time} seats={props.seats} takenSeats={props.takenSeats} expenses={props.expenses} />}
                            />
                            <CardContent style={styles.ImageContent}>
                                <CardMedia image={props.imageUrl} style={styles.Image} />
                            </CardContent>
                        </RouterLink>
                    </CardActionArea>
                    <CardContent >
                        <Link href="#" style={styles.Location}>
                            <LocationOnIcon />{props.location}
                        </Link><br />
                        <Typography variant="body2" color="textSecondary" component="p">
                            {props.description}
                        </Typography><br />
                        <RouterLink style={(fullReservation? styles.LinkDisabled : styles.LinkEnabled)} to={`/dinner/${props.id}`} >
                            <Button disabled={fullReservation}  fullWidth variant={"contained"} color={"primary" }>
                                {(fullReservation?"Maks antall påmeldte":"Se flere detaljer")}
                            </Button>
                        </RouterLink>
                    </CardContent>
                <CardActions disableSpacing/>
                </Card>
            </Grid>
        </ErrorBoundary>
    )
}

function DinnerCardFallback() {
    let styles = {
        Grid: {
            margin: "0",
            width: "375px",
        },
        ImageContent: {
            padding: 0
        },
    }
    return (
        <Grid item sm={6} md={4} lg={3} style={styles.Grid}>
            <Card>
                <CardActionArea>
                    <CardHeader
                        title={<Skeleton/>}
                        subheader={<Skeleton/>}
                    />
                    <CardContent style={styles.ImageContent}>
                        <Skeleton variant="rect" height={200}/>
                    </CardContent>
                </CardActionArea>
                <CardContent >
                    <Skeleton/>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <Skeleton/>
                    </Typography><br/>
                    <Skeleton>
                        <Button fullWidth variant={"contained"} color={"primary" }>
                            Stuff here to fill the entire width of the card
                        </Button>
                    </Skeleton>
                </CardContent>
                <CardActions disableSpacing/>
            </Card>
        </Grid>
    )
}

export default DinnerCard