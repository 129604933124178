import {Button, ButtonProps, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {ReactNode} from "react";


interface ButtonWithLoadingProps extends ButtonProps{
    isLoading: boolean
    children?: ReactNode
}

const useStyles = makeStyles({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})
// Button component with a loading state in which it displays a spinner
export default function ButtonWithLoading({isLoading, children, ...rest}: ButtonWithLoadingProps) {
    const classes = useStyles()
    return (
        <Button
            disabled={isLoading}
            {...rest}
        >
            {children}
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
    )

}