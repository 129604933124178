import {ChangeEvent, ReactNode, useState} from "react"
import {User, Dinner, Review, Conversation} from "../../types"
import {useOnGet, useOnGetMany, useQuery} from "@typesaurus/react"
import {add, batch,collection, Doc, where, field, ref} from "typesaurus"
import {useAuthState} from "react-firebase-hooks/auth";
import Navbar from "../components/Navbar"
import ProfileFeed from "./ProfileFeed"
import ProfileInfo from "./ProfileInfo"
import {useParams} from "react-router-dom"
import Footer from "../components/Footer";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {auth} from "../firebase";
import CircularProgressDelayed from "../components/CircularProgressDelayed";
import {AppBar, Box, CircularProgress, Container, Tab, Tabs, Typography} from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import {Rating} from "@material-ui/lab";
import ReviewBox from "../components/ReviewBox";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
    }),
);
const users = collection<User>('users')
const dinners = collection<Dinner>('dinners')

function Profile() {
    const classes = useStyles()

    // Retrieve ID from url
    const {id} = useParams<{id: string}>();
    let [user, userState] = useOnGet(users, id)
    let [joinedDinners] = useOnGetMany(dinners, user?.data?.joinedDinners?.map(dinner => dinner.id))
    const [authUser] = useAuthState(auth)
    // Retrieve the user's dinners (all, expired and upcoming)
    const dinnerIds = user ? user.data.dinners.map(v => v ? v.id : "") : []
    const [dinnerList] = useOnGetMany(dinners, dinnerIds)
    const expiredDinners = dinnerList?.filter(dinner => dinner.data.time < new Date())
    const upcomingDinners = dinnerList?.filter(dinner => dinner.data.time > new Date())
    // 0: Profile, 1: Reviews
    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };
    let styles = {
        Icon: {
            margin: "10px 5px 0 0"
        },
        Typography: {
            margin: "-10px 0 -40px"
        },
    }

    // Function for starting a conversation with another user
    const startConversation: () => Promise<string> = async () => {
        if (authUser) {
            const conversations = collection<Conversation>('conversations')
            const conversation = await add(conversations, {
                users: [ref(users, id), ref(users, authUser.uid)],
                created: new Date()
            })
            const {update, commit} = batch()
            update(users, authUser.uid, [ // Update currentUser's conversation collection
                field(['conversations', id], conversation.id)
            ])
            update(users, id, [ // Update profile owner's conversation collection
                field(['conversations', authUser.uid], conversation.id)
            ])
            await commit() // Commit updates
            return conversation.id
        }
        return Promise.reject("No user")
    }

    if (userState.loading) { // User doc is being retrieved from db
        return (
            <>
                <Navbar backArrow/>
                <CircularProgressDelayed/>
            </>
        )
    }
    if (userState.error) { // User could not be loaded
        console.error(userState.error)
        return (
            <>
                <Navbar backArrow />
                The user could not be loaded
            </>
        )
    }
    return (
        <>
            <div className={classes.root}>
            <Navbar backArrow/>
            <ProfileInfo onStartConversation={startConversation} currentUserId={authUser?.uid || ""} user={user}/>
            <Container >
                <AppBar component={"div"} position="static">
                    <Tabs value={tabIndex} TabIndicatorProps={{style: {background:'white'}}} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Middagsoversikt" />
                        <Tab label="Omtaler" />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabIndex} index={0}>
                    <DinnerSection upcomingDinners={upcomingDinners} expiredDinners={expiredDinners} joinedDinners={joinedDinners} styles={styles} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <ReviewSection user={user} dinnerIds={dinnerIds} />
                </TabPanel>
            </Container>
            </div>
            <Footer />
        </>
    )
}

// Prop interfaces
interface tabPanelProps {
    children?: ReactNode;
    index: any;
    value: any;
}
interface dinnerOverview {
    upcomingDinners: Doc<Dinner>[] | undefined;
    expiredDinners: Doc<Dinner>[] | undefined;
    joinedDinners: Doc<Dinner>[] | undefined;
    styles: any;
}

// Tab content
function TabPanel(props: tabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// Dinner overview section
function DinnerSection(props:dinnerOverview) {
    return (
        <>
        {
            props.upcomingDinners && props.upcomingDinners.length > 0 &&
                <>
                    <Typography variant={"h5"} style={props.styles.Typography}>
                        <RestaurantIcon style={props.styles.Icon} />
                        Brukerens kommende middager
                    </Typography>
                    <ProfileFeed dinners={props.upcomingDinners} />
                </>
        }
        {
            props.expiredDinners && props.expiredDinners.length > 0 &&
                <>
                    <Typography variant={"h5"} style={props.styles.Typography}>
                        <HistoryIcon style={props.styles.Icon} />
                        Brukerens tidligere middager
                    </Typography>
                    <ProfileFeed dinners={props.expiredDinners} />
                </>
        }
        {
            props.joinedDinners && props.joinedDinners!.length > 0 &&
                <>
                    <Typography variant={"h5"} style={props.styles.Typography}>
                        <EventAvailableIcon style={props.styles.Icon} />
                        Alle middager der du er/var påmeldt
                    </Typography>
                    <ProfileFeed dinners={props.joinedDinners!.sort((a,b) => {
                        // "newest" dinners will appear first
                        if (a.data.time < b.data.time)
                            return 1
                        return -1
                    })} />
                </>
        }
        </>
    )
}
// Review section
function ReviewSection(props: {user:Doc<User> | null | undefined, dinnerIds:string[]}) {
    let styles = {
        Loading: {
            display: "flex",
            margin: "auto",
        }
    }

    let reviews = collection<Review>('reviews')
    // Retrieve the profile owner's received reviews
    let userReviews = useQuery(reviews, [
        where('dinnerId', 'in', props.dinnerIds)
    ])[0]
    // Calculate average rating
    let sum = 0
    userReviews?.forEach(review => sum += review.data.rating)
    let averageRating = userReviews ? sum / userReviews.length : 0
    if(!userReviews) {
        return (
            <CircularProgress style={styles.Loading} />
        )
    }

    return(
        <>
            <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography component="legend" variant={"h5"}>Gjennomsnittlig vurdering</Typography>
                <Rating precision={0.01} size={"large"} name="read-only" value={averageRating} readOnly />
                {
                    userReviews && userReviews.map(r => <ReviewBox key={r.ref.id} review={r} />)
                }
            </Box>
        </>
    )
}

export default Profile