import { useEffect, useState } from "react";
import {Link, useHistory, withRouter} from "react-router-dom"
import Navbar from "../components/Navbar"
import AllergyCheckboxes from "../components/AllergyCheckboxes";
import {
    Button,
    Card, CardActions, CardMedia,
    FormControl,
    FormControlLabel,
    Grid, IconButton, Radio, RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import {Image, RemoveCircle} from "@material-ui/icons";
import firebase from "firebase";
import {useAll, useOnGet} from "@typesaurus/react";
import {collection, update, field} from "typesaurus";
import {Allergy, User} from "../../types";
import ButtonWithLoading from "../components/ButtonWithLoading";
import {storage} from "../firebase";
import Footer from "../components/Footer";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

function reauthenticate(oldPassword:string) {
    let user = firebase.auth().currentUser
    let cred = firebase.auth.EmailAuthProvider.credential(user!.email!.toString(), oldPassword.toString())
    return user!.reauthenticateWithCredential(cred)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
    }),
);

function ProfileEdit() {
    const classes = useStyles()
    const history = useHistory()
    const [img, setImg] = useState<{file: File | undefined, url: string}>({file: undefined, url: ""})

    let styles = {
        GridContainer: {
            margin: "auto",
            marginTop: "20px",
            marginBottom: "20px"
        },
        GridItem: {
            margin: "5px 0 5px"
        },
        Card: {
            padding: 20,
            width: "100%"
        },
        Typography: {
            marginTop: "15px",

        },
        TextField: {
            margin: "20px 10px 0 10px"
        },
        CheckBox: {
            marginLeft: 10,
        },
        Button: {
            marginRight: "10px"
        },
        Link: {
            textDecoration: "none"
        },
        FormControl: {
            width: "100%"
        },
        ImagePreview: {
            width: 250,
            height: img.url === "" ? 0 : 250,
            margin: "auto",
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0)",
            borderRadius: 5
        },
        ImagePreviewCard: {
            width: 250,
            margin: "auto",
            height: img.url === "" ? 0 : "auto"
        },
    }

    // Retrieve logged in user
    const users = collection<User>('users')
    let loggedInUser = firebase.auth().currentUser
    let [user] = useOnGet(users, loggedInUser ? loggedInUser.uid : undefined)
    // Retrieve all allergies
    const allergies = collection<Allergy>('allergies')
    let [allAllergies] = useAll(allergies) // Get all allergies in collection
    const [loading, setLoading] = useState(false)

    // Text field states
    let [textFields, setTextFields] = useState({
        firstName: "",
        lastName: "",
        phoneNr: "",
        address: "",
        publicEmail: "",
        bio: "",
    })
    let [checkboxState, setCheckboxState] = useState(new Map<string, boolean>()); // allergies
    // Private account details
    let [accountEmail, setAccountEmail] = useState("")
    let [passwordOld, setPasswordOld] = useState("")
    let [passwordNew, setPasswordNew] = useState("")
    let [passwordNewConfirm, setPasswordNewConfirm] = useState("")

    // Create initial error map
    let [errorMessages, setErrorMessages] = useState(new Map<string, string>([
        ["passwordOld", ""],
        ["passwordNew", ""],
        ["firstName", ""],
        ["lastName", ""],
        ["emailPublic", ""],
        ["emailAccount", ""]
    ]))
    let [passwordUpdated, setPasswordUpdated] = useState(false)
    let [radioValue, setRadioValue] = useState("email") // Toggle between updating password and updating email
    let [characterCount, setCharacterCount] = useState(0) // Used for bio, max 280 characters long
    // Errormessages
    let updateErrorMessages = (key:string, value:string) => {
        let errorMap = new Map<string, string>(errorMessages)
        errorMap.set(key, value)
        setErrorMessages(errorMap)
    }
    // Function for updating multiple error messages simultaneously
    let updateSeveralErrorMessages = (keys:string[], values:string[]) => {
        let errorMap = new Map<string, string>(errorMessages)
        for (let i = 0; i<keys.length; i++) {
            errorMap.set(keys[i], values[i])
        }
        setErrorMessages(errorMap)
    }
    // Remove profile picture
    const handleRemovePicture = () => {
        setImg({file: undefined, url: ""})
    }
    // Populate text fields with user data
    useEffect(() => {
        if (user) {
            setTextFields({
                firstName: user.data.name.firstName,
                lastName: user.data.name.lastName,
                phoneNr: user.data.contact.phone || "",
                address: user.data.address || "",
                publicEmail: user.data.contact.email || "",
                bio: user.data.bio || ""
            })
            setImg({file: undefined, url: user.data.imgUrl || ""})
            setAccountEmail(loggedInUser!.email!)
            setCharacterCount(user.data.bio ? user!.data.bio.length : 0)
        }
    }, [loggedInUser, user])

    // If the form has been submitted
    async function submit() {
        if (!passwordUpdated && user) {
            setLoading(true)
            // Create array of allergies to update later
            let addedAllergies: Allergy[] = []
            // For each existing allergy
            allAllergies!.forEach((allergy) => {
                if (checkboxState.get(allergy.ref.id)) { // If it is checked
                    let allergyObj = { // Create an allergy obj
                        id: allergy.ref.id
                    }
                    addedAllergies.push(allergyObj) // Add to array
                }
            })
            // Update account email if radio button is toggled
            if (radioValue === "email" && loggedInUser && accountEmail !== loggedInUser.email) {
                // Control old password
                reauthenticate(passwordOld).then(() => {
                    firebase.auth().currentUser!.updateEmail(accountEmail).then(() => { // Update email
                        updateErrorMessages("emailAccount", "") // Clear error messages
                        history.push("/profile") // Go to profile page
                    }).catch((error) => {
                        updateErrorMessages("emailAccount", error.message)
                    })
                }).catch((error) => {
                    updateErrorMessages("passwordOld", error.message)
                })
            }
            // Update password if radio button is toggled
            if (radioValue === "password" && (passwordNew !== "" || passwordNewConfirm !== "") && !passwordUpdated) {
                if (passwordNew === passwordNewConfirm) {
                    // Reauthenticate user
                    reauthenticate(passwordOld).then(() => {
                        // Update password
                        firebase.auth().currentUser!.updatePassword(passwordNew).then(() => {
                            history.push("/profile") // Password was changed successfully - switch page
                            // Clear password error messages
                            updateSeveralErrorMessages(["passwordOld", "passwordNew"], ["", ""])
                        }).catch((error) => {
                            updateSeveralErrorMessages(["passwordOld", "passwordNew"], ["", error.message])
                            setPasswordUpdated(false)
                        })
                    }).catch((error) => {
                        updateSeveralErrorMessages(["passwordOld", "passwordNew"], [error.message, ""])
                        setPasswordUpdated(false)
                    })
                } else {
                    updateSeveralErrorMessages(["passwordOld", "passwordNew"], ["", "New passwords do not match"])
                    setPasswordUpdated(false)
                }
            }

            // Upload profile picture
            let imgUrl: string | undefined
            if (img.file) { // If an image has been uploaded
                const imageRef = storage.ref().child('images/' + user.ref.id + '/' + img.file.name)
                try {
                    await imageRef.put(img.file)
                    // This is kind of bad, but it works I guess.
                    imgUrl = "https://" + (process.env.REACT_APP_FIREBASE_ENV === "production" ? "" : "dev-") + "static.samspisnad.no/" + imageRef.fullPath
                } catch (e) {
                    console.error(e)
                    setLoading(false)
                    return
                }
            } else {
                imgUrl = img.url
            }

            // Update user document
            update(users, user.data.id, [
                field(['name', 'firstName'], (textFields.firstName !== user.data.name.firstName ? textFields.firstName : user.data.name.firstName)),
                field(['name', 'lastName'], (textFields.lastName !== user.data.name.lastName ? textFields.lastName : user.data.name.lastName)),
                field(['contact', 'phone'], (textFields.phoneNr !== user.data.contact.phone ? textFields.phoneNr : user.data.contact.phone)),
                field('address', (textFields.address !== user.data.address ? textFields.address : user.data.address)),
                field('allergies', addedAllergies),
                field('bio', textFields.bio),
                field(['contact', 'email'], (textFields.publicEmail !== user.data.contact.email ? textFields.publicEmail : user.data.contact.email)),
                field(['imgUrl'], (imgUrl)),
            ])
                .then(() => {
                    history.push("/profile") // Go to profile
                }
            )
                .catch((error) => {
                    setLoading(false)
                    window.alert(error.message)
                })
        }
    }
    return (
        <>
            <div className={classes.root}>
                <Navbar backArrow/>
                <form noValidate autoComplete={"off"}>
                    <Grid item lg={5} xs={11} style={styles.GridContainer} >
                        <Card style={styles.Card}>
                            <Grid item style={styles.GridContainer} lg={12} xs={11}>

                                {/* Title */}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant={"h4"}>Rediger Offentlig Profil</Typography>
                                </Grid>

                                {/* Name */}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Hei, mitt navn er...
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label={"Fornavn"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.firstName}
                                        onChange={(e) => setTextFields({
                                            ...textFields,
                                            firstName: e.target.value
                                        })}
                                        required fullWidth
                                        error={errorMessages.get("firstName") !== ""}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label={"Etternavn"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.lastName}
                                        onChange={(e) => setTextFields({
                                            ...textFields,
                                            lastName: e.target.value
                                        })}
                                        required fullWidth
                                        error={errorMessages.get("lastName") !== ""}
                                    />
                                </Grid>

                                {/*Profile picture*/}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant="body1" style={styles.Typography}>
                                        Jeg ser ut som...
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        startIcon={<Image/>}
                                    >
                                        Velg Bilde
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={e => {
                                                setImg({file: e.target.files![0], url: URL.createObjectURL(e.target.files![0])})
                                            }}
                                            accept="image/jpg image/png"
                                            multiple
                                        />
                                    </Button>
                                    <Card style={styles.ImagePreviewCard}>
                                        <CardMedia
                                            style={styles.ImagePreview}
                                            image={img.url}
                                        />
                                        <CardActions>
                                            <IconButton onClick={handleRemovePicture}>
                                                <RemoveCircle/>
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>

                                {/* Phone */}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Telefonnummeret mitt er...
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label={"Tlf.Nr."}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.phoneNr}
                                        onChange={(e) => setTextFields({
                                            ...textFields,
                                            phoneNr: e.target.value
                                        })}
                                        fullWidth
                                    />
                                </Grid>

                                {/* Address */}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Adressen min er...
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label={"Adresse"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.address}
                                        onChange={(e) => setTextFields({
                                            ...textFields,
                                            address: e.target.value
                                        })}
                                        fullWidth />
                                </Grid>

                                {/* Email */}
                                <Grid item lg={10} style={styles.GridItem} >
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Den offentlige kontakt-e-posten min er...
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label={"eksempel@domene.com"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.publicEmail}
                                        onChange={(e) => setTextFields({
                                            ...textFields,
                                            publicEmail: e.target.value
                                        })}
                                        fullWidth
                                        error={errorMessages.get("emailPublic") !== ""}
                                    />
                                </Grid>

                                {/* Allergies/Preferences */}
                                <Grid item lg={10} style={styles.GridItem} >
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Mine preferanser og allergener er...
                                    </Typography>
                                    <AllergyCheckboxes
                                        userAllergies={user ?
                                        (user.data.allergies ? user.data.allergies.map(allergy => allergy.id) : [])
                                        :
                                        []
                                    }
                                        checkboxState={checkboxState}
                                        setCheckboxState={setCheckboxState}
                                        allAllergies={allAllergies}
                                    />
                                </Grid>

                                {/* Biography */}
                                <Grid item lg={10} style={styles.GridItem} >
                                    <Typography variant={"body1"} style={styles.Typography}>
                                        Biografien min... (Maks 280 tegn)
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label={"Fortell litt om deg selv... (" + characterCount + "/280)"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        value={textFields.bio}
                                        onChange={(e) => {
                                            setTextFields({...textFields, bio: e.target.value})
                                            setCharacterCount(e.target.value.length)
                                        }}
                                        fullWidth multiline
                                        rows={5}
                                        inputProps={{ maxLength: 280 }}
                                    />
                                </Grid>
                                <br />

                                {/* Old password */}
                                <Grid item lg={10} style={styles.GridItem}>
                                    <Typography variant={"h4"}>Rediger Private Kontoopplysninger</Typography>
                                    <Typography variant={"body1"} style={styles.Typography} color={"primary"}>
                                        Redigering av kontoopplysninger krever at du skriver inn ditt gamle passord:
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        type="password"
                                        autoComplete="current-password"
                                        label={"Gammelt passord"}
                                        color={"primary"}
                                        style={styles.TextField}
                                        onChange={(e) => setPasswordOld(e.target.value)}
                                        fullWidth
                                        error={errorMessages.get("passwordOld") !== ""}
                                        helperText={errorMessages.get("passwordOld")}
                                    />
                                </Grid>
                                <FormControl component="fieldset" style={styles.FormControl}>
                                    <RadioGroup
                                        value={radioValue}
                                        onChange={() => radioValue === "email" ? setRadioValue("password") : setRadioValue("email")}>
                                        {/* Change account email */}
                                        <Grid item lg={10} style={styles.GridItem} >
                                            <FormControlLabel value={"email"} control={<Radio color={"primary"} />} label={
                                                <Typography variant={"h5"}>Endre e-post</Typography>
                                            } />
                                            <Typography
                                                variant={"body1"}
                                                color={radioValue === "password" ? "textSecondary" : "textPrimary"}
                                                style={styles.Typography}
                                            >
                                                E-posten jeg logger inn med er...
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                label={"eksempel@domene.com"}
                                                color={"primary"}
                                                style={styles.TextField}
                                                value={accountEmail}
                                                disabled={radioValue === "password"}
                                                onChange={(e) => setAccountEmail(e.target.value)}
                                                required fullWidth
                                                error={errorMessages.get("emailAccount") !== ""}
                                            />
                                        </Grid>

                                        {/* Change password */}
                                        <Grid item lg={10} style={styles.GridItem} >
                                            <FormControlLabel value={"password"} control={<Radio color={"primary"} />} label={
                                                <Typography variant={"h5"}>Endre passord</Typography>
                                            } />
                                            <Typography
                                                variant={"body1"}
                                                color={radioValue === "email" ? "textSecondary" : "textPrimary"}
                                                style={styles.Typography}
                                            >
                                                Det nye passordet mitt er...
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                type="password"
                                                autoComplete="new-password"
                                                label={"Nytt passord"}
                                                color={"primary"}
                                                style={styles.TextField}
                                                onChange={(e) => setPasswordNew(e.target.value)}
                                                fullWidth
                                                disabled={radioValue === "email"}
                                                error={errorMessages.get("passwordNew") !== ""}
                                            />
                                            <Typography
                                                variant={"body1"}
                                                color={radioValue === "email" ? "textSecondary" : "textPrimary"}
                                                style={styles.Typography}
                                            >
                                                Bekreft det nye passordet...
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                type="password"
                                                label={"Nytt passord"}
                                                color={"primary"}
                                                style={styles.TextField}
                                                onChange={(e) => setPasswordNewConfirm(e.target.value)}
                                                fullWidth
                                                disabled={radioValue === "email"}
                                                error={errorMessages.get("passwordNew") !== ""}
                                                helperText={errorMessages.get("passwordNew")}
                                            />

                                        </Grid>

                                    </RadioGroup>
                                </FormControl>
                                <br /><br />

                                {/* Buttons */}
                                <Grid item lg={10}>
                                    <ButtonWithLoading
                                        variant="contained"
                                        color="primary"
                                        style={styles.Button}
                                        onClick={submit}
                                        isLoading={loading}
                                    >
                                        Bekreft
                                    </ButtonWithLoading>
                                    <Link to={"/profile"} style={styles.Link} >
                                        <Button variant="contained" color="default">
                                            Avbryt
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </form>
                <br />
            </div>
            <Footer />
        </>
    )
}

export default withRouter(ProfileEdit)