import DinnerPage from "./DinnerPage";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase"

// Show DinnerPage if user is authenticated
export default function Dinner() {
    const [user] = useAuthState(auth)
    return (
        <div>
            {user && <DinnerPage user={user}/>}
        </div>
    )
}
