import React, {useEffect, useState} from "react"
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    makeStyles,
    Button,
    useTheme,
    useMediaQuery,
    CardActions,
    Fab,
    FormControl,
    InputLabel,
    Input,
    CircularProgress,
    IconButton,
    Tooltip
} from "@material-ui/core"
import DinnerSubheader from "../components/DinnerSubheader";
import LocationOnIcon from "@material-ui/icons/LocationOn"
import  AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import Navbar from "../components/Navbar";
import {useParams} from "react-router-dom";
import {useGet, useGetMany, useOnGet, useOnQuery} from "@typesaurus/react";
import {collection, Doc, field, ref, update, upset, add, get, query, where, remove} from "typesaurus";
import {Dinner, Review, User} from "../../types";
import UserCard from "../components/UserCard";
import {ErrorBoundary} from "react-error-boundary";
import CircularProgressDelayed from "../components/CircularProgressDelayed";
import {Chat, RemoveCircle} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import firebase from '../firebase';
import Footer from "../components/Footer";
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Rating from '@material-ui/lab/Rating';
import ButtonWithLoading from "../components/ButtonWithLoading";
import ReportIcon from '@material-ui/icons/Report';
import useIsAdmin from "../utils/useIsAdmin";

const dinners = collection<Dinner>('dinners')
const users = collection<User>('users')
const reviews = collection<Review>('reviews')

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 10,
      },
    Card: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: "10vw",
            marginRight: "10vw",
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: "20vw",
            marginRight: "20vw",
        },
        marginTop: "15px",
        marginBottom: "15px",
        paddingBottom: "30px"
      },
    Location:{
        alignItems: "auto",
        fontSize:"16px",
        marginTop: "-15px",
    },
    media: {
      height: 0,
      paddingTop: "40%",
    },
    mediaGrayscale: {
        height: 0,
        paddingTop: "40%",
        filter: "grayscale(100%)",
    },
    fab: {
        position: 'fixed',
        bottom: "5%",
        right: "20%",
      },
    typography: {
        fontSize: '1.2rem',
    },
    actionIcon: {
        marginRight: 10,
    },
    link: {
        textDecoration: "none",
    },
    icon: {
        marginLeft: "5px",
    },
    DinnerInfo: {
        display: "flex",
        flexWrap: "wrap"
    },
    SpaceDiv: {
        width: "10px"
    },
    AllergyList: {
        display: "flex",
        alignItems: "center",
        marginTop: 5,
        width: "100%",
    },
    report: {
        display: 'flex',
        alignItems: 'right',
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}))

export default function DinnerPage(props: {user: firebase.User}) {
    const classes = useStyles();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const {id} = useParams<{id: string}>(); // Retrieve dinner-id from url id-parameter
    const [dinner, dinnerState] = useOnGet(dinners, id);
    const participantIds: string[] = dinner !== undefined && dinner !== null ? dinner.data.participants.map(v => v ? v.id: "") : []
    const [participants, participantState] = useGetMany(users, participantIds)
    const [owner] = useGet(users, dinner?.data.owner.id)
    const [loggedInUser] = useGet(users, props.user.uid)
    const [currentUser] = useGet(users, props.user.uid) // Get current user document
    const dinnerId = dinner?.ref.id //get id form link
    const isOwner = !owner || owner.ref.id === props.user.uid
    const isAdmin = useIsAdmin(props.user)
    const isExpired = dinner && dinner.data ? dinner.data.time < new Date() : false
    const isParticipant = participants ? participantIds.includes(props.user.uid) : false

    // Function triggers when user joins dinner
    const joinDinner = async () => {
        if (isOwner || isExpired) { // If currentUser is owner or dinner has expired
            return
        }
        if (dinner && loggedInUser) {
            try {
                const userRef = ref(users, props.user.uid) // Retrieve reference to user doc
                // @ts-ignore Update the dinner's participants list
                await update(dinners, id, [field('participants', [...dinner.data.participants, userRef])])
                // @ts-ignore Update the currentUser's joinedDinners list
                await upset(users, props.user.uid, {'joinedDinners': [...loggedInUser.data.joinedDinners, dinner.ref]})
            } catch (e) {
                console.log(e)  
            }
        }
    }
    // Function triggers when user leaves dinner
    const leaveDinner = async () => {
        if (isOwner || isExpired) {
            return
        }
        if (dinner && loggedInUser) {
            try {
                const userRef = ref(users, props.user.uid)
                await update(dinners, id, [field('participants', dinner.data.participants.filter(v => v.id !== userRef.id))])
                await update(users, props.user.uid, [field('joinedDinners', loggedInUser.data.joinedDinners!.filter(v => v.id !== id))])
            } catch (e) {
                console.log(e)
            }
        }
    }
    if (dinner && participants){
        const isInDinner = dinner.data.participants.map(v => v.id).includes(props.user.uid) // If user is in dinner
        let fullDinner = dinner.data.participants.length === dinner.data.seats // If dinner is full
        return (
            <>
            <Grid component="main" className={classes.root}>
                <Navbar backArrow/>
                {((isOwner && !isExpired) || isAdmin) &&
                <RouterLink to={`/editDinner/${dinnerId}`} className={classes.link}>
                        <Fab variant="extended" color="primary" aria-label="add" className={classes.fab}>
                            <CreateIcon className={classes.icon} />Rediger middag
                        </Fab>
                    </RouterLink>
                }
                <Card className={classes.Card}>
                    <CardMedia
                        className={isExpired ? classes.mediaGrayscale : classes.media}
                        image={dinner.data.imgUrl}
                    />
                    <div className={classes.report}>
                    <RouterLink to={`/ReportDinner/${dinnerId}`} className={classes.link}>
                            <Tooltip title="Rapporter middag" aria-label="add">
                                <IconButton aria-label="report problem" color="secondary">
                                    <ReportIcon />
                                </IconButton>
                            </Tooltip>
                        </RouterLink>

                    {
                        ((dinner.data.participants.length < dinner.data.seats) ?
                    <CardHeader
                        title={dinner.data.title}
                        subheader={
                            <div className={classes.DinnerInfo}>
                                <DinnerSubheader time={dinner.data.time} takenSeats={dinner.data.participants.length} seats={dinner.data.seats} expenses={dinner.data.expenses}/>
                                <div className={classes.SpaceDiv}/>
                                <LocationOnIcon/>{dinner.data.location}
                                <div className={classes.AllergyList}>
                                    {
                                        dinner.data.allergies.length > 0 ?
                                            <><WarningIcon />{(dinner.data.allergies.map(allergy => allergy.id).join(", "))}</> :
                                            <><WarningIcon />Ingen allergener</>
                                    }
                                </div>
                            </div>
                        }
                        action={<>
                            {(smUp && !isOwner) && <JoinLeaveButtons onJoin={joinDinner} onLeave={leaveDinner} isInDinner={isInDinner} fullDinner={fullDinner} isExpired={isExpired}/>
                            }
                            {(isInDinner || isOwner) && <Button variant="contained" component={RouterLink} to={"/chat/" + dinnerId} startIcon={<Chat/>}>Gå til samtale</Button>}
                        </>}
                    />
                    :
                        <CardHeader
                            title={dinner.data.title}
                            subheader={
                                <div className={classes.DinnerInfo}>
                                    <DinnerSubheader time={dinner.data.time} takenSeats={dinner.data.participants.length} seats={dinner.data.seats} expenses={dinner.data.expenses}/>
                                    <div className={classes.SpaceDiv}/>
                                    <LocationOnIcon/>{dinner.data.location}
                                    <div className={classes.AllergyList}>
                                        {
                                            dinner.data.allergies.length > 0 ?
                                                <><WarningIcon />{(dinner.data.allergies.map(allergy => allergy.id).join(", "))}</> :
                                                <><WarningIcon />Ingen allergener</>
                                        }
                                    </div>
                                </div>
                            }
                            action={<>
                                {(smUp && !isOwner) && <JoinLeaveButtons onJoin={joinDinner} onLeave={leaveDinner} isInDinner={isInDinner} fullDinner={fullDinner} isExpired={isExpired}/>
                                }
                                {(isInDinner || isOwner) && <Button variant="contained" component={RouterLink} to={"/chat/" + dinnerId} startIcon={<Chat/>}>Gå til samtale</Button>}
                            </>}
                        />
                    )}
                    {(!smUp && !isOwner && !isExpired) &&
                        <CardActions>
                            <JoinLeaveButtons onJoin={joinDinner} onLeave={leaveDinner} isInDinner={isInDinner} fullDinner={fullDinner}  isExpired={isExpired}/>
                        </CardActions>
                    }
                    </div>
                    <CardContent>
                        <Typography className={classes.typography}>
                            {dinner.data.description}
                        </Typography>
                    </CardContent>
                    {
                        isParticipant && isExpired && dinner && !isOwner &&
                            <GiveRating currentUser={currentUser} dinner={dinner} owner={owner} />
                    }
                    <CardContent>
                        <Typography variant="h5">Deltakere på middagen</Typography>
                        <Grid container spacing={5}>
                            {owner &&
                                <ErrorBoundary key={owner.ref.id} FallbackComponent={Card}>
                                        <UserCard user={owner.data} owner={true} currentUserOwnsDinner={false} dinnerExpired={isExpired}/>
                                </ErrorBoundary>
                            }
                            {participants && participants.filter(user => user.ref.id !== owner?.ref.id).map(user =>
                                <ErrorBoundary key={user.ref.id} FallbackComponent={Card}>
                                    {
                                        (isOwner || isAdmin) ?
                                        <UserCard user={user.data} owner={false} currentUserOwnsDinner={true} dinnerExpired={isExpired}  /> :
                                        <UserCard user={user.data} owner={false} currentUserOwnsDinner={false} dinnerExpired={isExpired}/>
                                    }
                                </ErrorBoundary>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Footer />
            </>
        )
    } else if (dinnerState.loading || participantState.loading) { // If dinner or participants are loading
        return(
            <>
                <Navbar backArrow/>
                <CircularProgressDelayed/>
                <Footer />
            </>
        )
    }
    else {
        return(
          <div><Navbar /></div>
        )
    }
}
// Section for rating the dinner
function GiveRating(props:{currentUser:Doc<User> | null | undefined, dinner:Doc<Dinner> | null | undefined, owner:Doc<User> | null | undefined}) {
    let styles = {
        TypographyRating: {
            display: "flex",
            height: "5vh",
            alignItems: "center",
        },
        InputField: {
            width: "100%",
        },
        CardContent: {
            width: "100%",
        },
        FormControl: {
            width: "100%",
        },
        Button: {
            marginTop: "10px",
            marginRight: "5px",
        },
        Spinner: {
            display: "flex",
            margin: "auto",
        }
    }
    let [rating, setRating] = useState<number>(5 )
    let [review, setReview] = useState<string>("")
    let [isProcessing, setIsProcessing] = useState<boolean>(false)
    // Check if user has already reviewed this dinner
    let [existingReviews, existingReviewStatus] = useOnQuery(reviews, [
        // @ts-ignore
        where('authorId', '==', props.currentUser?.ref.id),
        where('dinnerId', '==', props.dinner?.ref.id!)
    ])
    let reviewExists = existingReviews ? existingReviews.length > 0 : false
    useEffect(() => {
        if (reviewExists) {
            // If the review exists, update the rating and text in the form
            let existingReview = existingReviews![0]
            setRating(existingReview.data.rating)
            setReview(existingReview.data.text)
        }
    }, [reviewExists, existingReviews])

    if (existingReviewStatus.loading) {
        return (
            <>
                <CircularProgress style={styles.Spinner}/>
            </>
        )
    }
    return (
        <CardContent style={styles.CardContent}>
            <Typography variant="h5">
                <StarOutlineIcon />
                Skriv omtale
            </Typography>
            <Typography variant="body2" color={"textSecondary"}>
                Omtalen vil vises på arrangørens profil
            </Typography>
            <Typography variant={"body1"} style={styles.TypographyRating}>
                <Rating
                    name="simple-controlled"
                    value={rating}
                    precision={0.5}
                    onChange={(e: any, newValue: number | null) => {
                        if (typeof newValue === "number") {
                            setRating(newValue);
                        } else {
                            setRating(0)
                        }
                    }}
                />
            </Typography>
            <FormControl style={styles.FormControl}>
                <InputLabel>Din omtale</InputLabel>
                <Input
                    style={styles.InputField}
                    multiline
                    value={review}
                    onChange={(event) => setReview(event.target.value)}
                />
            </FormControl>
            <ButtonWithLoading
                variant={"contained"}
                color={"primary"}
                style={styles.Button}
                isLoading={isProcessing}
                onClick={() => {
                    if (props.currentUser && props.dinner) {
                        setIsProcessing(true) // Disable buttons
                        publishReview(rating, review, props.currentUser.ref.id, props.dinner.ref.id, props.owner!).then(() => {
                            setIsProcessing(false)  // Enable buttons
                        })
                    }
                }}
            >
                {
                    reviewExists ? "Oppdater omtale" : "Publiser omtale"
                }
            </ButtonWithLoading>
            {
                reviewExists &&
                    <ButtonWithLoading
                        variant={"contained"}
                        color={"secondary"}
                        style={styles.Button}
                        isLoading={isProcessing}
                        onClick={() => {
                            if (props.currentUser && props.dinner && props.owner) {
                                setIsProcessing(true) // Disable buttons
                                deleteReview(existingReviews![0], props.owner, props.currentUser).then(() => {
                                    setIsProcessing(false)  // Enable buttons
                                    setReview("")
                                    setRating(5)
                                })
                            }
                        }}
                    >
                        Slett eksisterende omtale
                    </ButtonWithLoading>
            }
        </CardContent>
    )
}

async function publishReview(rating:number, reviewText:string, authorId:string, dinnerId:string, owner:Doc<User>) {
    // Check if review exists
    const existingReview = await query(reviews, [
        where('authorId', '==', authorId),
        where('dinnerId', '==', dinnerId!)
    ])
    // If review already exists, update existing review
    if (existingReview.length > 0) {
        await updateReview(rating, reviewText, authorId, dinnerId, existingReview[0])
        return
    }

    let timestamp = new Date()
    let review = {
        "rating": rating,
        "dinnerId": dinnerId,
        "text": reviewText,
        "authorId": authorId,
        "time": timestamp
    }
    let publishedReview = await add(reviews, review) // publish new review
    // Update dinner-owner's receivedReviews attribute
    await update(users, owner!.ref.id, [field('receivedReviews', [...owner!.data.receivedReviews, publishedReview])])

    // Update author's givenReveiews attribute
    let authorRef = await firebase.auth().currentUser
    let author = await get(users, authorRef!.uid)
    await update(users, authorRef!.uid, [field('givenReviews', [...author!.data.givenReviews, publishedReview])])
    window.alert("Omtale publisert!") // TODO: Add proper prompt, for example in utils class
}

async function updateReview(rating:number, reviewText:string, authorId:string, dinnerId:string, oldReview:Doc<Review>) {
    // Update review
    let newReview = {
        "rating": rating,
        "dinnerId": dinnerId,
        "text": reviewText,
        "authorId": authorId,
        "time": new Date()
    }
    await update(reviews, oldReview.ref.id, newReview)
    window.alert("Omtale oppdatert!") // TODO: Add proper prompt, for example in utils class
}

async function deleteReview(review:Doc<Review>, owner:Doc<User>, author:Doc<User>) {
    let ownerReceivedReviews = owner.data.receivedReviews
    let authorGivenReviews = author.data.givenReviews
    // Filter out the review that is meant to be deleted
    ownerReceivedReviews = ownerReceivedReviews.filter(r => r.id !== review.ref.id)
    authorGivenReviews = authorGivenReviews.filter(r => r.id !== review.ref.id)
    // Update owner and author with new arrays
    await update(users, owner.ref.id, [field('receivedReviews', ownerReceivedReviews)])
    await update(users, author.ref.id, [field('givenReviews', authorGivenReviews)])
    // Delete review from reviews-collection
    let id = review.ref.id
    await remove(reviews, id)
    window.alert("Omtale slettet!") // TODO: Add proper prompt, for example in utils class
}

function JoinLeaveButtons({onJoin, onLeave, isInDinner, fullDinner, isExpired}: {onJoin: () => void, onLeave: () => void, isInDinner: boolean, fullDinner: boolean, isExpired: boolean}) {
    const classes = useStyles()
    return (
        <>
            <Button
                variant="contained"
                color={isInDinner ? "secondary" : "primary"}
                onClick={isInDinner ? onLeave: onJoin}
                disabled={fullDinner || isExpired}
            >
                {isInDinner ?
                    <>
                        <RemoveCircle className={classes.actionIcon}/>  Forlat middag
                    </>
                    :
                    (fullDinner ? <>Fullt! Ingen påmelding</> : <><AddIcon className={classes.actionIcon}/> Meld deg på middag</>)
                }
            </Button>
        </>
    )
}

