import { Button, Card, CardContent, CardHeader, CardMedia, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Dinner, User } from "../../types";
import { Person, RemoveCircle } from "@material-ui/icons";
import { Link as RouterLink, useParams } from "react-router-dom";
import RoomServiceRoundedIcon from '@material-ui/icons/RoomServiceRounded';
import { useOnGet } from "@typesaurus/react";
import { collection, field, ref, update } from "typesaurus";

const dinners = collection<Dinner>('dinners')
const users = collection<User>('users')

const useStyles = makeStyles({
    Grid: {
        margin: "0",
        width: "375px",
    },
    Location: {
        display: "flex",
        alignItems: "center"
    },
    Card: {
        padding: 0
    },
    Image: {
        height: 0,
        paddingTop: '56.25%'
    },
    ImageContent: {
        padding: 0,
        textAlign: "center"
    },
    LinkEnabled: {
        textDecoration: "none"
    },
    LinkDisabled: { // Used when the dinner has max amount of participants
        textDecoration: "none",
        pointerEvents: "none"
    },
    RouterLink: {
        textDecoration: "none"
    },
    Icon: {
        marginBottom: "-5px"
    },
})

interface UserCardInterface {
    user: User;
    owner: boolean;
    currentUserOwnsDinner: boolean;
    dinnerExpired: boolean;
    fullWidth?: boolean;
}

export default function UserCard(props: UserCardInterface) {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const [dinner, dinnerState] = useOnGet(dinners, id);
    console.log(dinnerState)

    async function LeaveDinner(props: User) { // Function triggers when user leaves dinner
        if (dinner) {
            try {
                const userRef = ref(users, props.id)
                await update(dinners, id, [field('participants', dinner.data.participants.filter(v => v.id !== userRef.id))])
                await update(users, props.id, [field('joinedDinners', props.dinners.filter( v => v?.id !== id))])
            } catch (e) {
                console.log(e)
            }
        }
    }
    return (
        <Grid item sm={12} md={props.fullWidth ? 12 : 6} xl={props.fullWidth ? 12 : 4} className={classes.Grid}>
            <RouterLink to={"/profile/" + props.user.id} className={classes.RouterLink}>
                <Card className={classes.Card} >
                    {(props.currentUserOwnsDinner) &&
                        <CardHeader
                            title={props.user.name.firstName + " " + props.user.name.lastName}
                            subheader={
                                <>
                                    {
                                        props.owner ?
                                            <Typography variant={"body1"}>
                                                <RoomServiceRoundedIcon className={classes.Icon} />
                                                Middagsarrangør
                                            </Typography> :
                                            ""
                                    }
                                    <UserAddress address={props.user.address} />
                                </>
                            }
                            action={
                                // Only display remove-user-button if dinner has not expired
                                !props.dinnerExpired &&
                                <Button
                                    color="secondary"
                                    endIcon={<RemoveCircle />}
                                    onClick={(click) => {
                                        click.stopPropagation();
                                        click.preventDefault();
                                        LeaveDinner(props.user)}}>
                                </Button>
                            }
                        />}
                    {(!props.currentUserOwnsDinner) &&
                        <CardHeader
                            title={props.user.name.firstName + " " + props.user.name.lastName}
                            subheader={
                                <>
                                    {
                                        props.owner ?
                                            <Typography variant={"body1"}>
                                                <RoomServiceRoundedIcon className={classes.Icon} />
                                                Middagsarrangør
                                            </Typography> :
                                            ""
                                    }
                                    <UserAddress address={props.user.address} />
                                </>
                            }
                        />
                    }

                    <CardContent className={classes.ImageContent}>
                        {props.user.imgUrl ? <CardMedia image={props.user.imgUrl} className={classes.Image} /> : <Person style={{ fontSize: 200, margin: "0 auto" }} />}
                    </CardContent>
                    <CardContent>
                        <Typography>{props.user.bio}</Typography>
                    </CardContent>
                </Card>
            </RouterLink>
        </Grid>
    )
}

// Display user address
function UserAddress(props: { address: string | undefined }) {
    const classes = useStyles()
    return (
        <Link href="" className={classes.Location}>
            <LocationOnIcon />{props.address}
        </Link>
    )
}