import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography} from '@material-ui/core';
import Navbar from '../components/Navbar';
import {auth} from "../firebase";
import ButtonWithLoading from '../components/ButtonWithLoading';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom"
import { add, collection } from 'typesaurus';
import { Report } from '../../types';
import Footer from "../components/Footer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "white",
            paddingBottom: 10,
        },
        GridContainer: {
            margin: "auto",
            marginTop: "20px",
            marginBottom: "15px",
        },
        GridItem: {
            margin: "5px 0 3px",
            padding: 10
        },
        Typography: {
            marginTop: "15px",
        },
        TextField: {
            margin: "20px 10px 0 10px"
        },
        Button: {
            marginRight: "10px",
            marginTop: "5px"
        },
        Link: {
            textDecoration: "none"
        },
    }),
);

export default function ReportDinner() {
    const classes = useStyles();
    const loggedInUser = auth.currentUser
    const history = useHistory()
    const {id} = useParams<{id: string}>();
    let [loading, setLoading] = useState(false)
    let [reportingReason, setReportingReason] = useState("")
    let [error, setError] = useState("")

    function submit(){
        setLoading(true)
        if (reportingReason.length < 2 ){
            setError("Du må beskrive hvorfor middagen blir rapportert")
            setLoading(false)
        }
        else {
            setError("")
            const reports = collection<Report>('reports')
            if (loggedInUser){
                add(reports, // Add report
                    {
                        dinnerID: id.toString(),
                        userID: loggedInUser.uid,
                        id : "",
                        reportReason: reportingReason,
                    }
            ).then(() => {
                setLoading(false)
                history.push("/")
            })
            }
        }
    }
    return (
        <>
        <div className={classes.root}>
            <Navbar backArrow/>
            <Grid item lg={6} xs={9} className={classes.GridContainer}>
                <form autoComplete="off">
                    <Typography variant={"h5"} className={classes.Typography}>
                        Hvorfor rapporteres middagen?
                    </Typography>
                    <Grid item lg={10} className={classes.GridItem}>
                    <TextField
                        id="rapporteringsgrunn"
                        label="Rapporteringsgrunnlag"
                        multiline
                        rows={4}
                        helperText={error ? "Beskriv hvorfor du ønsker å rapportere middagen" : ""}
                        variant="outlined"
                        color="primary"
                        value={reportingReason}
                        onChange={ (text) => setReportingReason(text.target.value)}
                        fullWidth
                        required
                        error={error !== ""}
                    />
                    </Grid>
                    <Grid item lg={10} className={classes.GridItem}>
                    <ButtonWithLoading isLoading={loading} variant="contained" color="primary" className={classes.Button} onClick={submit}>
                        Opprett
                    </ButtonWithLoading>
                    <RouterLink to={"/"} className={classes.Link}>
                        <Button variant="contained" color="default" className={classes.Button}>
                            Avbryt
                        </Button>
                    </RouterLink>
                </Grid>
                </form>
            </Grid>
        </div>
        <Footer />
        </>
    )
}