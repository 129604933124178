
// Delete user
import {Dinner, User} from "../types";
import {collection, field, getMany, remove, update} from "typesaurus";

async function deleteUser(user:User) {
    let users = collection<User>('users')
    let dinners = collection<Dinner>('dinners')
    // Delete all user-hosted dinners:
    // 0. Get all user-hosted dinners
    let userDinnerIDs = user.dinners.map(dinner => dinner.id)
    let userDinners = await getMany(dinners, userDinnerIDs)

    let participantIDs:string[] = [] // Array of all participant IDs (to update later)
    // 1. For each user-hosted dinner
    userDinners.forEach((dinner) => {
        // For each participant in the user hosted dinner
        dinner.data.participants.map(participant => participant.id).forEach(participantID => {
            // If the participant has not yet been added to the array
            if (!participantIDs.includes(participantID)) {
                participantIDs.push(participantID) // Add it to the array
            }
        })

    })
    let participants = await getMany(users, participantIDs)
    // For each participant
    await participants.forEach(participant => {
        // Get list of joined dinners
        // Filter out the dinners hosted by the user that is being deleted
        if (participant.data.joinedDinners && participant.data.joinedDinners.length > 0) {
            let newParticipantDinnerList = participant.data.joinedDinners.filter(joinedDinner =>
                // Only include dinners that are not in the dinner list of the user that is being deleted
                !user.dinners.map(dinner => dinner.id).includes(joinedDinner.id)
            )
            // Update the participant's joinedDinners field with the new dinnerList
            if (participant.ref.id) {
                update(users, participant.ref.id, [field('joinedDinners', newParticipantDinnerList)])
            }
        }
    })

    // 3. Delete dinners
    await userDinners.forEach(dinner => {
        if (dinner.ref.id) {
            remove(dinners, dinner.ref.id)
        }
    })
    // Find all dinners where the user is a participant
    let userJoinedDinnerIDs = user.joinedDinners?.map(joinedDinner => joinedDinner.id)
    // If the user has joined some dinners
    if (userJoinedDinnerIDs && userJoinedDinnerIDs.length !== 0) {
        let userJoinedDinners = await getMany(dinners, userJoinedDinnerIDs) // Get dinners
        if (userJoinedDinners) {
            await userJoinedDinners.forEach(dinner => { // For each dinner
                // Remove the user from the participant list
                let newParticipantList = dinner.data.participants.filter(participant => participant.id !== user.id)
                if (dinner.ref.id) {
                    update(dinners, dinner.ref.id, [field('participants', newParticipantList)])
                }
            })
        }
    }
    if (user.id) {
        await remove(users, user.id)
    }
    console.log("User deleted!")
}

export { deleteUser }