import {ChangeEvent, ReactNode, useState} from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import Navbar from "../components/Navbar";
import { Report, User } from "../../types";
import {collection, remove} from "typesaurus";
import { deleteUser } from "../utils"
import { useOnAll } from "@typesaurus/react";
import {Button, Container, Divider, Tab, Tabs, Typography} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Footer from "../components/Footer";

let styles = {
    root: {
        backgroundColor: "white",
    },
    DataGrid: {
        margin: "20px auto",
        height: "70vh",
        width: "100%",
        backgroundColor: "white",
    },
    RouterLink: {
        textDecoration: "none",
        width: "100%",
    },
    Divider: {
        marginBottom: "15px",
    },
    Content: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "white",
        marginTop: 15,
    }
}

interface TabPanelProps {
    children?: ReactNode;
    index: any;
    value: any;
}

export default function AdminPanel() {
    let users = collection<User>('users')
    let [retrievedUsers] = useOnAll(users)

    let reports = collection<Report>('reports')
    let [retrievedReports] = useOnAll(reports)
    const [currentTab, setTab] = useState(Number)

    async function deleteReport(props: string) {
        console.log(props)
        try {
            await remove(reports, props)
        } catch (e) {
            console.log(e)
        }
    }

    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const userRows: GridRowsProp = [];
    const reportRows: GridRowsProp = [];

    const reportColumns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 200, renderCell: reportID => (
                <RouterLink to={"reportedDinner/" + reportID.value as string} style={styles.RouterLink}>
                    <Button fullWidth variant={"contained"} color={"primary"}>
                        Vis Rapport
                    </Button>
                </RouterLink>
            )
        },
        { field: 'reportReason', headerName: 'Rapporteringsgrunn', width: 500 },
        {
            field: 'seeDinner', headerName: 'Vis middag', width: 200, renderCell: dinnerID => (
                <RouterLink to={"dinner/" + dinnerID.value as string} style={styles.RouterLink}>
                    <Button fullWidth variant={"contained"} color={"primary"}>
                        Vis middag
                    </Button>
                </RouterLink>
            )
        },
        {
            field: 'seeUser', headerName: "Se bruker", width: 200, renderCell: userID => (
                <RouterLink to={"profile/" + userID.value as string} style={styles.RouterLink}>
                    <Button fullWidth variant={"contained"} color={"primary"}>
                        Vis bruker
                    </Button>
                </RouterLink>
            )
        },
        {
            field: 'deleteReport', headerName: "Slett rapport", width: 200, renderCell: reportID => (
                <Button fullWidth variant={"outlined"} color={"secondary"}
                        onClick={() => deleteReport(reportID.value as string)}>
                    Slett rapport
                </Button>
            )
        }
    ]

    const userColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 270},
        {field: 'name', headerName: 'Navn', width: 200},
        {field: 'email', headerName: 'E-post', width: 200},
        {field: 'phone', headerName: 'Telefonnummer', width: 160},
        {
            field: 'seeProfile', headerName: 'Vis profil', width: 150, renderCell: userID => (
                <RouterLink to={"profile/" + userID.value as string} style={styles.RouterLink}>
                    <Button fullWidth variant={"contained"} color={"primary"}>
                        Vis profil
                    </Button>
                </RouterLink>
            )
        },
        {
            field: 'deleteUser', headerName: 'Slett bruker', width: 150, renderCell: (user) => {
                let userObj = (user.value as User)
                let allowDelete = !userObj.admin
                if (!allowDelete) {
                    return (
                        <Button fullWidth variant={"outlined"} disabled color={"secondary"}
                                onClick={() => window.alert("Kan ikke slette bruker som er innlogget eller administrator!")}>
                            Slett bruker
                        </Button>
                    )
                }
                return (
                    <Button fullWidth variant={"outlined"} color={"secondary"}
                            onClick={() => deleteUser(user.value as User)}>
                        Slett bruker
                    </Button>
                )
            }
        },
    ];

    if (userRows.length === 0 && retrievedUsers) {
        retrievedUsers.forEach(user => {
            let field = {
                "id": user.data.id,
                "name": user.data.name.firstName + " " + user.data.name.lastName,
                "seeProfile": user.data.id,
                "deleteUser": user.data,
                "email": user.data.contact.email,
                "phone": user.data.contact.phone,
            }
            userRows.push(field)
        })
    }

    if (reportRows.length === 0 && retrievedReports) {
        retrievedReports.forEach(report => {
            let field = {
                "id": report.ref.id,
                "reportReason": report.data.reportReason,
                "seeDinner": report.data.dinnerID,
                "seeUser": report.data.userID,
                "deleteReport": report.ref.id
            }
            reportRows.push(field)
        })
    }

    function a11yProps(index: any) {
        return {
            id: `admin-tab-${index}`,
            'aria-controls': `admin-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;
        return (
            <div
                style={styles.DataGrid}
                role="tabpanel"
                hidden={value !== index}
                id={`admin-tabpanel-${index}`}
                aria-labelledby={`admin-tab-${index}`}
                {...other}
            >
                {value === 0 && (
                    <DataGrid rows={userRows} columns={userColumns}/>
                )}
                {value === 1 && (
                    <DataGrid rows={reportRows} columns={reportColumns}/>
                )}
            </div>
        );
    }

    return (
        <>
            <div style={styles.root}>
                <Navbar backArrow={true}/>
                {/* @ts-ignore */}
                <Container style={styles.Content}>
                    <Typography variant={"h4"}>
                        Administrator-panel
                    </Typography>
                    <Divider style={styles.Divider}/>
                    <Tabs value={currentTab} onChange={handleTabChange} aria-label="admin tab">
                        <Tab label="Brukeroversikt"  {...a11yProps(0)} />
                        <Tab label="MiddagsRapportering" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                    </TabPanel>
                </Container>
            </div>
            <Footer/>
        </>
    );

}