import {Dispatch, SetStateAction} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {Grid, Input} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: "50vh",
    margin: 20
  },
  input: {
    width: 42,
  },
});

interface MenuSliderInterface {
    setValue:Dispatch<SetStateAction<number | string | number[]>>,
    value:number|string|number[],
    minValue?: number
}
// Slider-component used to decide the max number of participants on a dinner
export default function MenuSlider(props:MenuSliderInterface){
  const classes = useStyles();


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== '') {
          let sliderValue = Number(event.target.value)
          sliderValue = sliderValue > 15 ? 15 : sliderValue // check that value is below 15
          sliderValue = sliderValue < 0 ? 0 : sliderValue // check that value is above 0
          props.setValue(sliderValue)
      } else {
          props.setValue("")
      }
  };
  
  const handleSliderChange = (event: any, newValue: number | number[]) => {
    props.setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography id="menu-slider" gutterBottom>
        Antall personer
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof props.value === 'number' ? props.value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            max={15}
            min={typeof props.minValue =="number" ? props.minValue : 2}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={props.value}
            margin="dense"
            onChange={handleInputChange}
            inputProps={{
              step: 1,
              min: 2,
              max: 15,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
          Personer
        </Grid>
      </Grid>
    </div>
  );
}